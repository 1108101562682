import {
  CoreTracker,
  FetchRequestFactory,
  PlaybackEventBase,
  PlaybackEventTypes,
  PlaybackTracker,
  Stream,
  StreamingTicketData,
  TPlaybackEvent,
  TrackingData,
  TTrackerConfiguration,
  TTrackingInfoEventPayload,
  YouboraTracker,
} from "@telia-company/tv.web-playback-sdk";
import {
  TEventMethods,
  TPlaybackConfig,
  TPlayerConfig,
  TPlayerEvent,
  TServiceLayerConfig,
  WatchMode,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

import { TServices } from "../@types/types";
import { getNielsenTracker } from "./nielsen";

export type TRegisterTrackersOptions = {
  config: TServiceLayerConfig;
  event: TEventMethods;
  playback: TPlaybackConfig;
  playerVersion: string;
  requestFactory: FetchRequestFactory;
  services: TServices;
};

const playerName = "web-player";
const playerEngine = "no engine yet";

export const registerTrackers = ({
  config: {
    application,
    applicationSessionId,
    deviceId,
    deviceModel,
    deviceVendor,
    enableNielsenTracker = true,
    enableCoreTracker = true,
    operatingSystemFirmwareVersion,
    operatingSystemName = "unknown",
    operatingSystemVersion = "unknown",
    playbackTrackingUrl,
    serviceBrand,
    serviceCountry,
    serviceId,
    serviceName,
    user,
    youboraAccount,
  },
  event,
  playback: { playbackSpec },
  playerVersion,
  requestFactory,
  services: { sgwService, trackingManager },
}: TRegisterTrackersOptions): (() => void) => {
  const trackerConfig: TTrackerConfiguration = {
    application,
    device: {
      clientName: "web",
      deviceId,
      deviceModel,
      deviceVendor,
    },
    operatingSystem: {
      operatingSystemFirmwareVersion,
      operatingSystemName,
      operatingSystemVersion,
    },
    player: {
      playerEngine,
      playerName,
      playerVersion,
    },
    service: {
      serviceBrand,
      serviceCountry,
      serviceId,
      serviceName,
    },
    session: {
      applicationSessionId,
    },
    user,
  };

  /**
   * Nielsen
   */

  if (enableNielsenTracker) {
    const nlsn = getNielsenTracker(trackerConfig, event);

    trackingManager.registerTracker(nlsn);
  }

  /**
   * Core
   */

  if (enableCoreTracker) {
    const ct = new CoreTracker(trackerConfig);

    trackingManager.registerTracker(ct);
  }

  /**
   * Youbora
   */

  const yb = new YouboraTracker({
    accountId: youboraAccount,
    useProductAnalytics: true,
    ...trackerConfig,
  });

  yb.initialize({
    contentId: playbackSpec.videoId,
    live: playbackSpec.watchMode === WatchMode.LIVE,
  });

  trackingManager.registerTracker(yb);

  /**
   * Playback
   */

  const playbackTracker = new PlaybackTracker({
    ...trackerConfig,
    endpoint: playbackTrackingUrl,
  });

  playbackTracker.attachRequestFactory(requestFactory);

  trackingManager.registerTracker(playbackTracker);

  /**
   * Hook the manager to the event channel
   */

  const handler = (
    _type: PlaybackEventTypes | WebPlayerEventType,
    payload: TPlaybackEvent | TPlayerEvent
  ) => {
    const isPlaybackEvent = (
      p: TPlaybackEvent | TPlayerEvent
    ): p is TPlaybackEvent => p instanceof PlaybackEventBase;

    if (isPlaybackEvent(payload)) {
      trackingManager.track(payload);

      if (payload.type === PlaybackEventTypes.TIME_UPDATE) {
        sgwService.timeUpdate(payload);
      }
    }
  };

  event.on("*", handler);

  /**
   * Return an unsubscribe method
   */

  return () => {
    trackingManager.reset();
    trackingManager.unregisterAllTrackers({ destroy: true });

    event.off("*", handler);
  };
};

export type TBuildTrackingInfoOptions = {
  config?: TPlayerConfig;
  playback?: TPlaybackConfig;
  playerVersion?: string;
  stream?: Stream;
  ticketData?: StreamingTicketData;
};

export const getTrackingInfoPayload = ({
  config,
  playback,
  playerVersion,
  stream,
  ticketData,
}: TBuildTrackingInfoOptions): TTrackingInfoEventPayload => ({
  content: {
    ...(stream
      ? {
          contentUrl: stream?.url,
          stitched: (stream?.adType?.indexOf("YOSPACE") ?? -1) > -1,
        }
      : {}),
    ...(playback?.playbackSpec ? { playbackSpec: playback.playbackSpec } : {}),
    playNext: false, // TODO dynamic
  },
  playback: {
    startTimeInSeconds: playback?.startTimeInSeconds || 0,
  },
  ...(playerVersion
    ? {
        player: {
          playerEngine,
          playerName,
          playerVersion,
        },
      }
    : {}),
  ...(config
    ? {
        session: {
          applicationSessionId: config.applicationSessionId,
        },
        user: config.user,
      }
    : {}),
  ...(ticketData ? { tracking: ticketData.tracking as TrackingData } : {}),
});
