import styled from "@emotion/styled";

import { ButtonReset } from "./buttons/base";
import {
  absolutePositionFullSizeBlock,
  centeredFlexIcon,
  fadeAnimation,
} from "./styles";

export const AbsolutePositionFullSizeDiv = styled.div`
  ${absolutePositionFullSizeBlock}
`;

export const SixteenByNineContainer = styled.div<{
  backgroundColor?: string;
  fullscreen?: boolean;
}>`
  position: relative;
  outline: none;
  margin: 0 auto;
  flex: 1 1 0;
  max-height: 100vh;

  &:before {
    background-color: ${({ backgroundColor }) => backgroundColor || "#000"};
    display: inline-block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
`;

export const GridIcon = styled(ButtonReset)<{ smallLayout?: boolean }>`
  ${centeredFlexIcon}

  cursor: pointer;

  --microtip-transition-delay: 0s;
  ::after {
    background: rgba(0, 0, 0, 0.8) !important;
    border-radius: 0.25em !important;
    padding: 0 0.75em !important;
    font-size: 0.9em !important;
    line-height: 1.8em !important;
    margin-bottom: ${({ smallLayout }) =>
      smallLayout ? "-1em !important" : "1.8em !important"};
  }
  ::before {
    background: unset !important;
  }
  &:hover {
    --microtip-transition-delay: 1s;
    > svg {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }

  > svg {
    min-height: 2.3rem;
    min-width: 100%;

    ${({ smallLayout }) =>
      smallLayout
        ? ""
        : `max-height: 6rem;
         max-width: 6rem;`}

    fill-opacity: 0.7;
    stroke-opacity: 0.7;
    width: 55%;
    height: 55%;
  }
`;

export const UiGrid = styled.div<{
  gradient: string;
  smallLayout: boolean | null;
  visible: boolean;
}>`
  ${({ visible }) => fadeAnimation(visible)}
  padding: ${({ smallLayout }) =>
    smallLayout ? "3% 2% 1% 2%" : "3% 3.5% 1% 3.5%;"};
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-gap: 0.5%;
  background: ${({ gradient }) => gradient}
  height: 100%;
`;

export const TextScale = styled.span<{
  textScale: number;
}>`
  font-size: ${({ textScale }) => textScale}em;
`;

export const CssReset = styled.span<{ fontFamily: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
  color: #fff;
  font-size: 16px;
  line-height: 1;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export const UiHoverElement = styled(AbsolutePositionFullSizeDiv)`
  outline: none;
  user-select: none;
`;

export const Bar = styled.div<{ horizontal?: boolean }>`
  ${({ horizontal }) => (horizontal ? `height: 0.25em;` : `width: 0.25em;`)}

  border-radius: 1em;
`;

export const Dot = styled.div<{ horizontal?: boolean }>`
  ${({ horizontal }) =>
    horizontal ? "margin-left: -0.45em;" : "margin-top: -0.45em;"}

  width: 1em;
  height: 1em;
  border-radius: 1em;

  background: #fff;
`;
