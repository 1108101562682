import { FC, ReactNode, RefObject, useCallback } from "react";

import { UiEventTypes } from "../constants";
import { useUiTimeout } from "../hooks/ui-timeout.hook";
import { throttle } from "../utils";
import {
  SmallLayoutContext,
  useCustomStyles,
  useDispatch,
} from "./context-provider";
import { CssReset, TextScale, UiHoverElement } from "./elements";
import { useFullscreen } from "./fullscreen.hook";

export type UiWrapperProps = {
  children: ReactNode;
  scaleFactor: number;
  sizeRef: RefObject<HTMLDivElement>;
  smallLayout: boolean | null;
};

export const UiWrapper: FC<UiWrapperProps> = ({
  children,
  scaleFactor,
  sizeRef,
  smallLayout,
}) => {
  const dispatch = useDispatch();
  const { fontFamily } = useCustomStyles();
  const { toggleFullscreen } = useFullscreen();

  const hideUi = useCallback(() => {
    dispatch({
      payload: {},
      type: UiEventTypes.HIDE_UI,
    });
  }, [dispatch]);

  const uiTimeout = useUiTimeout(dispatch);

  return (
    <UiHoverElement
      onClick={uiTimeout}
      onDoubleClick={toggleFullscreen}
      onFocus={uiTimeout}
      onKeyDown={uiTimeout}
      onMouseEnter={uiTimeout}
      onMouseLeave={hideUi}
      onPointerMove={throttle(uiTimeout, 200)}
      ref={sizeRef}
    >
      {smallLayout !== null && (
        <SmallLayoutContext.Provider value={smallLayout}>
          <CssReset fontFamily={fontFamily}>
            <TextScale textScale={scaleFactor}>{children}</TextScale>
          </CssReset>
        </SmallLayoutContext.Provider>
      )}
    </UiHoverElement>
  );
};
