import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Track } from "@useless-media-public/useless-sender-web";
import React from "react";

import { SvgCheckmark } from "~/components/Icons";

const StyledContainer = styled.div`
  margin: 0 12px;
`;

const StyledTitle = styled.div`
  margin-bottom: 16px;
  min-width: 175px;

  ${({ theme }) => css`
    color: ${theme.designTokens.color.text.primary};
    font-family: ${theme.designTokens.typography.headingTertiary.fontFamily};
    font-size: ${theme.designTokens.typography.headingTertiary.fontSize};
    font-weight: ${theme.designTokens.typography.headingTertiary.fontWeight};
    line-height: ${theme.designTokens.typography.headingTertiary.lineHeight};
    text-transform: ${theme.designTokens.typography.headingTertiary
      .textTransform};
  `}
`;

export type UselessStyledListItemProps = {
  isActive?: boolean;
};

const StyledItem = styled.div<UselessStyledListItemProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 8px;

  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.designTokens.color.text.primary};
          font-family: ${theme.designTokens.typography.textSecondaryEmphasis
            .fontFamily};
          font-size: ${theme.designTokens.typography.textSecondaryEmphasis
            .fontSize};
          font-weight: ${theme.designTokens.typography.textSecondaryEmphasis
            .fontWeight};
          line-height: ${theme.designTokens.typography.textSecondaryEmphasis
            .lineHeight};
        `
      : css`
          &:hover {
            opacity: 0.8;
          }

          color: ${theme.designTokens.color.text.secondary};
          font-family: ${theme.designTokens.typography.textSecondary
            .fontFamily};
          font-size: ${theme.designTokens.typography.textSecondary.fontSize};
          font-weight: ${theme.designTokens.typography.textSecondary
            .fontWeight};
          line-height: ${theme.designTokens.typography.textSecondary
            .lineHeight};
        `}
`;

const StyledItemIconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const StyledSvgCheckMark = styled(SvgCheckmark)`
  width: 100%;
  height: 100%;
`;

export type UselessTrackListProps = {
  accessibilityTranslation?: string;
  activeTrack?: Track;
  onClickTrack: (track: Track) => void;
  title?: string;
  tracks?: Track[];
};

export const UselessTrackList: React.FC<UselessTrackListProps> = ({
  accessibilityTranslation,
  activeTrack,
  onClickTrack,
  title,
  tracks,
}) => (
  <StyledContainer>
    <StyledTitle>{title}</StyledTitle>
    {tracks?.map((track) => (
      <StyledItem
        isActive={activeTrack?.id === track.id}
        key={track.id}
        onClick={() => onClickTrack(track)}
        role="none" // TODO: add keyboard navigation later
      >
        <StyledItemIconContainer>
          {activeTrack?.id === track.id && <StyledSvgCheckMark />}
        </StyledItemIconContainer>
        {track.name}{" "}
        {track.accessibility && accessibilityTranslation
          ? `(${accessibilityTranslation})`
          : ""}
      </StyledItem>
    ))}
  </StyledContainer>
);
