import {
  FetchRequestFactory,
  INormalizedError,
  MetadataService,
} from "@telia-company/tv.web-playback-sdk";
import {
  getFromLocalStorage,
  LocalStorageKey,
  setInLocalStorage,
} from "@telia-company/tv.web-player-shared";
import {
  TPlayerConfig,
  TUsePlayerOptions,
} from "@telia-company/tv.web-player-ui";
import { createContext, Dispatch, useContext, useReducer } from "react";

import {
  getPlayerConfig,
  TeliaStageEnv,
  TEnvConf,
  TServiceCountryConf,
} from "../config/ref-app-config";

export type TAction =
  | TChangeEnvAction
  | TChangeServiceCountryAction
  | TLoginStartAction
  | TLoginSuccessAction
  | TLogoutAction
  | TPlaybackAction
  | TPlayerConfAction
  | TTogglePlayerAction;

export type TChangeEnvAction = {
  payload: TEnvConf;
  type: "CHANGE_ENV";
};

export type TChangeServiceCountryAction = {
  payload: TServiceCountryConf;
  type: "CHANGE_SERVICE_COUNTRY";
};

export type TLoginStartAction = {
  type: "LOGIN_START";
};

export type TLoginSuccessAction = {
  payload: {
    chromecastAuthStuff: {
      profileHeader: null | string;
      refreshToken: string | undefined;
    };
    isLoggedIn: boolean;
    userId: null | string;
  };
  type: "LOGIN_SUCCESS";
};

export type TLogoutAction = {
  type: "LOGGED_OUT";
};

export type TPlaybackAction = {
  payload: TUsePlayerOptions["playback"];
  type: "PLAYBACK_SPEC_UPDATED";
};

export type TPlayerConfAction = {
  payload: TUsePlayerOptions["conf"];
  type: "PLAYER_CONF";
};
export type TTogglePlayerAction = {
  payload: boolean;
  type: "TOGGLE_PLAYER";
};

export const playbackAction = (
  payload: TUsePlayerOptions["playback"]
): TPlaybackAction => ({
  payload,
  type: "PLAYBACK_SPEC_UPDATED",
});

export const togglePlayerAction = (payload: boolean): TTogglePlayerAction => ({
  payload,
  type: "TOGGLE_PLAYER",
});

export const loginStartAction = (): TLoginStartAction => ({
  type: "LOGIN_START",
});

export const loginSuccessAction = (payload: {
  chromecastAuthStuff: {
    profileHeader: null | string;
    refreshToken: string | undefined;
  };
  isLoggedIn: boolean;
  userId: null | string;
}): TLoginSuccessAction => ({
  payload,
  type: "LOGIN_SUCCESS",
});

export const logoutAction = (): TLogoutAction => ({
  type: "LOGGED_OUT",
});

export const changeEnvAction = (payload: TEnvConf): TChangeEnvAction => ({
  payload,
  type: "CHANGE_ENV",
});

export const changeServiceCountryAction = (
  payload: TServiceCountryConf
): TChangeServiceCountryAction => ({
  payload,
  type: "CHANGE_SERVICE_COUNTRY",
});

export const changePlayerConfAction = (
  payload: TUsePlayerOptions["conf"]
): TPlayerConfAction => ({
  payload,
  type: "PLAYER_CONF",
});

export type TRefState = {
  auth: {
    profileHeader: null | string;
    refreshToken: string | undefined;
  } | null;
  env: TEnvConf;
  isLoggedIn: boolean;
  metadataService: MetadataService;
  playback: null | TUsePlayerOptions["playback"];
  playerConf: null | TPlayerConfig;
  playerError: INormalizedError | null;
  requestFactory: FetchRequestFactory;
  serviceCountry: string;
  showPlayer: boolean;
  signingIn: boolean;
  userId: null | string;
};

const initialState: TRefState = {
  auth: null,
  env: getFromLocalStorage(LocalStorageKey.REF_APP_ENV) || TeliaStageEnv,
  isLoggedIn: false,
  metadataService: {} as MetadataService,
  playback: null,
  playerConf: null,
  playerError: null,
  // reducer is instantiated with these
  requestFactory: {} as FetchRequestFactory,
  serviceCountry:
    getFromLocalStorage(LocalStorageKey.REF_APP_SERVICECOUNTRY) || "SE",
  showPlayer: false,
  signingIn: false,
  userId: null,
};

const reducer = (state: TRefState, action: TAction): TRefState => {
  // eslint-disable-next-line no-console
  console.log(action);
  switch (action.type) {
    case "CHANGE_ENV":
      setInLocalStorage(LocalStorageKey.REF_APP_ENV, action.payload);
      return {
        ...state,
        env: action.payload,
        playerConf: null,
        userId: null,
      };
    case "CHANGE_SERVICE_COUNTRY":
      setInLocalStorage(
        LocalStorageKey.REF_APP_SERVICECOUNTRY,
        action.payload.serviceCountry
      );
      return {
        ...state,
        playerConf: null,
        serviceCountry: action.payload.serviceCountry,
        userId: null,
      };
    case "LOGGED_OUT":
      return {
        ...state,
        isLoggedIn: false,
        playerConf: null,
        signingIn: false,
        userId: null,
      };
    case "LOGIN_START":
      return {
        ...state,
        isLoggedIn: false,
        signingIn: true,
        userId: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        auth: action.payload.chromecastAuthStuff,
        isLoggedIn: true,
        signingIn: false,
        userId: action.payload.userId,
      };
    case "PLAYBACK_SPEC_UPDATED":
      return {
        ...state,
        playback: action.payload,
        playerError: null,
        showPlayer: true,
      };
    case "PLAYER_CONF":
      return {
        ...state,
        playerConf: getPlayerConfig({
          applicationSessionId: action.payload.applicationSessionId,
          deviceId: action.payload.deviceId,
          env: state.env.id,
          iAuth: action.payload.auth,
          serviceCountry: action.payload.serviceCountry,
          userId: state.userId,
        }),
      };
    case "TOGGLE_PLAYER":
      return {
        ...state,
        showPlayer: action.payload,
      };
    default:
      return state;
  }
};

export type TRefStateProps = {
  metadataService: MetadataService;
  requestFactory: FetchRequestFactory;
};

export const useRefStateReducer = ({
  metadataService,
  requestFactory,
}: TRefStateProps): [TRefState, Dispatch<TAction>] =>
  useReducer(reducer, {
    ...initialState,
    metadataService,
    requestFactory,
  });

export const RefAppStateContext = createContext<TRefState>(initialState);
export const useRefAppState = (): TRefState => useContext(RefAppStateContext);

export const RefAppDispatchContext = createContext<Dispatch<TAction>>(
  {} as Dispatch<TAction>
);
export const useRefAppDispatch = (): Dispatch<TAction> =>
  useContext(RefAppDispatchContext);
