import {
  ApplicationInfo,
  AudioTrackChangedEvent,
  BitrateChangedEvent,
  BrowserInfo,
  BufferedEvent,
  BufferingEvent,
  CdnChangedEvent,
  CustomTrackingEvent,
  DrmType,
  DroppedFramesEvent,
  EventRecord,
  IAuthenticationComponent,
  LoadedEvent,
  ManifestTypeChangedEvent,
  PausedEvent,
  PlaybackErrorEvent,
  PlaybackEventTypes,
  PlaybackRestrictions,
  PlayEvent,
  PlayingEvent,
  Program,
  SeekedEvent,
  SeekingEvent,
  ServiceConfig,
  Stream,
  StreamCueEvent,
  TAudioTrackType,
  TextTrackChangedEvent,
  TextTrackVisibilityEvent,
  TFetchMethod,
  TimelineBlockData,
  TimeUpdateEvent,
  TPlaybackEvent,
  TTextTrackType,
  UserInfo,
  VolumeChangedEvent,
  YospacePlaybackFeature,
} from "@telia-company/tv.web-playback-sdk";
import { Emitter, Handler } from "mitt";

import {
  AccessControl,
  TLanguageCode,
  VideoIdType,
  WatchMode,
  WebPlayerEventType,
} from "./shared-constants";

export type TCallback = (...args: TUnfortunatelyAny) => void;

export type TMilliseconds = number;
export type TSeconds = number;

export type TShakaTrackChoice = {
  bandwidth: number;
  fromAdaptation: boolean;
  id: number;
  timestamp: number;
  type: string;
};

export type TShakaStateChange = {
  duration: number;
  state: string;
  timestamp: number;
};

export type TStatsBuffer = {
  end: number;
  start: number;
};

export type TPlayerStats = {
  bufferingTime?: number;
  buffers?: {
    audio: TStatsBuffer[];
    text: TStatsBuffer[];
    total: TStatsBuffer[];
    video: TStatsBuffer[];
  };
  corruptedFrames?: null | number;
  decodedFrames?: number;
  drmTimeSeconds?: number;
  droppedFrames?: number;
  estimatedBandwidth?: number;
  height?: number;
  licenseTime?: number;
  liveLatency?: number;
  loadLatency?: number;
  manifestTimeSeconds?: number;
  maxSegmentDuration?: number;
  pauseTime?: number;
  player?: string;
  playTime?: number;
  stateHistory?: Array<TShakaStateChange>;
  streamBandwidth?: number;
  switchHistory?: Array<TShakaTrackChoice>;
  version?: string;
  width?: number;
};

export type TUserConfig = {
  fallbackAudioLanguage?: keyof TLanguageCode;
  gdprConsent?: string;
  preferredAudioLanguage?: keyof TLanguageCode;
  preferredRegionalChannels?: Record<string, string>; // Telia Region Channel Map
  preferredSubtitleLanguage?: "off" | keyof TLanguageCode;
  stillWatching?: {
    gracePeriodMs: number;
    idleTimeLinearMs: number;
    idleTimeVodMs: number;
  };
};

export type TPlayerEvent =
  | TAdMarkersAvailableEvent
  | TAutoplayBlockerEvent
  | TBufferFullnessEvent
  | TConcurrentStreamsEvent
  | TControlsAvailableEvent
  | TEndOfStreamEvent
  | TInitialActiveTracksEvent
  | TIsLiveEvent
  | TLanguagesEvent
  | TPlaybackRestrictionsEvent
  | TSeekRangeUpdateEvent
  | TSpeedChangeEvent
  | TTimelineDataEvent
  | TUserInactivityEvent
  | TUserInactivityWarningEvent
  | TVideoElementReadyEvent;

export type TBaseEvent = {
  payload: Record<string, unknown>;
};

export type TControlsAvailableEvent = {
  payload: PlayerControls;
  type: WebPlayerEventType.CONTROLS_AVAILABLE;
};

export type TBufferFullnessEvent = {
  payload: {
    fullnessBetween0and1: number;
  };
  type: WebPlayerEventType.BUFFER_FULLNESS;
} & TBaseEvent;

export type TConcurrentStreamsEvent = {
  type: WebPlayerEventType.CONCURRENT_STREAMS;
} & TBaseEvent;

export type TUserInactivityEvent = {
  type: WebPlayerEventType.USER_INACTIVITY;
} & TBaseEvent;

export type TUserInactivityWarningEvent = {
  payload: {
    visible: boolean;
  };
  type: WebPlayerEventType.USER_INACTIVITY_WARNING;
} & TBaseEvent;

export type TAdMarkersAvailableEvent = {
  // for rendering them on the progress bar
  payload: number[];
  // ad marker positions as a percentage between 0 and 1
  type: WebPlayerEventType.AD_MARKERS;
};

export type TPlaybackRestrictionsEvent = {
  payload: {
    trickPlayRestrictions: {
      noFastForward?: boolean;
      noPause?: boolean;
      noRewind?: boolean;
    };
  };
  type: WebPlayerEventType.PLAYBACK_RESTRICTIONS;
} & TBaseEvent;

export type TTimelineDataEvent = {
  payload: {
    timeline: TimelineBlockData[];
  };
  type: WebPlayerEventType.TIMELINE_DATA;
} & TBaseEvent;

export type TTimelineRange = {
  end: TSeconds;
  start: TSeconds;
};

export type TTimeline = {
  closingCredits?: { patched?: boolean; type: string } & TTimelineRange;
  openingCredits?: { type: string } & TTimelineRange;
  recap?: { type: string } & TTimelineRange;
} | null;

export type TVideoElementReadyEvent = {
  payload: {
    textElement?: HTMLDivElement;
    videoElement: HTMLVideoElement;
  };
  type: WebPlayerEventType.VIDEO_ELEMENT_READY;
} & TBaseEvent;

export type TInitialActiveTracksEvent = {
  payload: {
    initialActiveTracks: {
      audio: null | TAudioTrack;
      text: null | TTextTrack;
    };
    textTrackVisible: boolean;
  };
  type: WebPlayerEventType.INITIAL_ACTIVE_TRACKS;
} & TBaseEvent;

export type TLanguagesEvent = {
  payload: {
    audioTracks?: Array<TAudioTrack>;
    textTracks?: Array<TTextTrack>;
  };
  type: WebPlayerEventType.LANGUAGES;
} & TBaseEvent;

export type TAutoplayBlockerEvent = {
  type: WebPlayerEventType.AUTOPLAY_BLOCKED;
} & TBaseEvent;

export type TSpeedChangeEvent = {
  payload: {
    newSpeed: number;
    previousSpeed: number;
  };
  type: WebPlayerEventType.SPEED_CHANGE;
} & TBaseEvent;

export type TEndOfStreamEvent = {
  payload: TimeUpdateEvent["payload"];
  type: WebPlayerEventType.END_OF_STREAM;
} & TBaseEvent;

export type TSeekRangeUpdateEvent = {
  payload: {
    seekRange: {
      end: number;
      start: number;
    };
    stats: TPlayerStats;
  };
  type: WebPlayerEventType.SEEK_RANGE_UPDATE;
};

export type TIsLiveEvent = {
  payload: {
    isLive: boolean;
  };
  type: WebPlayerEventType.IS_LIVE;
};

export type TThumbnailOptions = {
  percent: number;
};

export interface PlaybackSession {
  onConcurrentStreams: (id: string) => void;
}

export interface PlayerControls {
  getDuration: () => TSeconds | undefined;
  getPosition: () => TSeconds | undefined;
  getSpeed: () => number;
  getThumbnail: (options: TThumbnailOptions) => Promise<null | TThumbnail>;
  pause: () => void;
  play: () => void;
  seek: (position: "LIVE" | number) => void;
  setAudioTrack: (lang: TAudioTrack | undefined) => void; // undefined means select default track
  setSpeed: (speed: number) => void;
  setSubtitleTrack: (lang: TTextTrack | undefined) => void; // undefined means select default track
  setSubtitleVisibility: (visible: boolean) => void;
}

export interface AdSession {
  createControls: (e: MainPlayer, r: PlaybackRestrictions) => PlayerControls;
  getAdBreaks: () => YospacePlaybackFeature["advertisementBreaks"];
  getCurrentAdvertisementClickThroughUrl: YospacePlaybackFeature["getCurrentAdvertisementClickThroughUrl"];
  getCurrentAdvertisementDuration: YospacePlaybackFeature["getCurrentAdvertisementDuration"];
  getCurrentAdvertisementTime: YospacePlaybackFeature["getCurrentAdvertisementTime"];
  getDurationWithoutAds: () => number;
  getPositionWithAds: YospacePlaybackFeature["getPositionWithAds"];
  getPositionWithoutAds: YospacePlaybackFeature["getPositionWithoutAds"];
  getStreamUrl: () => string;
  isPlayingAd: () => boolean;
  timeUpdate: YospacePlaybackFeature["timeUpdate"];
}

export type TEngineOptions = {
  callbacks: TEngineCallbacks;
  event: Omit<TEventMethods, "all" | "clear" | "publish">;
  features: TPlayerFeatures;
  playback: TPlaybackConfig;
  restrictions?: TEngineVariantRestrictions;
  selectedStream: Stream;
  user: TUserConfig;
};

export interface MainPlayer extends PlayerControls {
  configure: (config: TUnfortunatelyAny) => void;
  getAudioTracks: () => Array<TAudioTrack>;
  getSubtitleTracks: () => Array<TTextTrack>;
  load: () => void;
  playerId: string;
  teardown: TCallback;
}

export type TCreateMainPlayer = (
  options: TEngineOptions
) => MainPlayer | Promise<MainPlayer>;

export type TPlayerFeatures = {
  disableAds?: boolean;
  disableDVR?: boolean;
  disableHlsjs?: boolean;
  enableUHD?: boolean;
  hideRelatedContent?: boolean;
  thumbnailScrubbing?: boolean;
  timers?: boolean;
};

export interface EngineLogic {
  capabilities: string[];
  createEngine: TCreateMainPlayer;
  // Should resolve the preferred platform DRM
  selectDrm: () => Promise<DrmType>;
  // A map of supported packagings per DRM
  supportedPackagings: Record<DrmType, TPackaging[]>;
}

export type TWildcardEventHandler = (
  type: PlaybackEventTypes | WebPlayerEventType,
  event: TPlaybackEvent | TPlayerEvent
) => void;

export type TSingleEventHandler = Handler<TPlaybackEvent | TPlayerEvent>;

export type TPublishEvent = TEventMethods["publish"];

export type TEventHandler = {
  addEventListeners: () => void;
  removeEventListeners: () => void;
};

export type PlayerEventRecord = {
  /**
   * @remarks The ad break positions as an array of numbers between 0-1, to indicate percentage-wise where in the progress
   * bar they should be displayed.
   */
  [WebPlayerEventType.AD_MARKERS]: TAdMarkersAvailableEvent;
  /**
   * @remarks Indicates that the browser denied automatic playback start, and a manual user action is
   * required to start the stream.
   */
  [WebPlayerEventType.AUTOPLAY_BLOCKED]: TAutoplayBlockerEvent;
  /**
   * @remarks Indicates between 0-1 a percentage of how full the buffer is. If the engine is configured
   * to attempt to buffer 30 seconds forward, 15 seconds of buffer would equal buffer fullness 0.5.
   */
  [WebPlayerEventType.BUFFER_FULLNESS]: TBufferFullnessEvent;
  /**
   * @remarks Concurrent streams detected. The session will be shut down.
   */
  [WebPlayerEventType.CONCURRENT_STREAMS]: TConcurrentStreamsEvent;
  /**
   * @remarks The controls for interacting with the stream are available. These controls should be used for all
   * interaction with the stream in order to guarantee that playback restrictions, ad sessions, and tracking are
   * properly applied. The payload contains the controls.
   *
   * {@link PlayerControls}
   */
  [WebPlayerEventType.CONTROLS_AVAILABLE]: TControlsAvailableEvent;
  /**
   * @remarks EOS is reached, the session will be shut down.
   */
  [WebPlayerEventType.END_OF_STREAM]: TEndOfStreamEvent;
  /**
   * @remarks OPTIONAL: Indicates to the app which audio and subtitle track will be initially selected,
   * and if the subtitles will be visible or not when the stream starts playing.
   */
  [WebPlayerEventType.INITIAL_ACTIVE_TRACKS]: TInitialActiveTracksEvent;
  /**
   * @remarks indicates if the currently playing stream is live or not.
   */
  [WebPlayerEventType.IS_LIVE]: TIsLiveEvent;
  /**
   * @remarks The event carries the available language tracks, audio and/or text. Can trigger multiple times
   * if tracks are added or removed from the underlying stream.
   */
  [WebPlayerEventType.LANGUAGES]: TLanguagesEvent;
  /**
   * @remarks Manifest switched from dynamic (live) to static (vod).
   */
  [WebPlayerEventType.MANIFEST_TYPE_CHANGED]: ManifestTypeChangedEvent;
  /**
   * @remarks Carries the restrictions that applies to the current stream, like noFastForward.
   */
  [WebPlayerEventType.PLAYBACK_RESTRICTIONS]: TPlaybackRestrictionsEvent;
  /**
   * @remarks The seek range for the stream has updated, relevant for contexts with a moving or growing window (timeshift/dvr/startover)
   */
  [WebPlayerEventType.SEEK_RANGE_UPDATE]: TSeekRangeUpdateEvent;
  /**
   * @remarks Indicates that the playback speed has changes. The event carries the new and the old value.
   */
  [WebPlayerEventType.SPEED_CHANGE]: TSpeedChangeEvent;
  /**
   * @remarks Timeline event contains information about where in the stream certain events occur, like intro, recap, or
   * end credits.
   */
  [WebPlayerEventType.TIMELINE_DATA]: TTimelineDataEvent;
  /**
   * @remarks User inactivity warning, indicating that the app should display an indication that the stream
   * will be shut down if they do not take action
   */
  [WebPlayerEventType.USER_INACTIVITY_WARNING]: TUserInactivityWarningEvent;
  /**
   * @remarks User inactivity detected. The session will be shut down.
   */
  [WebPlayerEventType.USER_INACTIVITY]: TUserInactivityEvent;
  /**
   * @remarks For MSE integrations, indicates to the app that the video element is prepared and ready
   * to be inserted in the DOM.
   */
  [WebPlayerEventType.VIDEO_ELEMENT_READY]: TVideoElementReadyEvent;
};

/**
 * @remarks The full list of events that can appear on the event channel.
 *
 * {@link TEngineCallbacks}
 */
export type WebPlayerEventRecord = EventRecord & PlayerEventRecord;

export type TEventMethods = {
  clear: () => void;
  publish: (arg: TPlaybackEvent | TPlayerEvent) => void;
} & Omit<Emitter<WebPlayerEventRecord>, "emit">;

export type TPackaging =
  | "DASH_MP4_CTR"
  | "HLS_CMAF_CBCS"
  | "HLS_TS_CBCS"
  | "HLS_TS_VMX"
  | "IGMP"
  | "RTSP";

export type TInitializePlaybackOptions = {
  disableSessionTimeout?: boolean;
  features: TPlayerFeatures;
  playback: TPlaybackConfig;
  restrictions?: TEngineVariantRestrictions;
  user: TUserConfig;
};

export type TInitializeServicesOptions = {
  conf: TServiceLayerConfig;
  version: string;
};

export type TPlaybackService = {
  destroy: () => void;
  event: {
    all: TEventMethods["all"];
    off: TEventMethods["off"];
    on: TEventMethods["on"];
    publishCustomTrackingEvent: (event: string) => void;
  };
  getMetadata: (
    playbackSpec: TPlaybackSpec,
    timestamp: null | number
  ) => Promise<TContentMetadata>;
  initializePlayback: (
    options: TInitializePlaybackOptions
  ) => null | PlaybackSession;
  initializeServices: (options: TInitializeServicesOptions) => void;
};

export type TVideoId = {
  videoId: string;
};

export type TSubscriptionEventPlaybackSpec = {
  accessControl: "SUBSCRIPTION" | AccessControl.SUBSCRIPTION;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "LIVE" | WatchMode.LIVE;
} & TVideoId;

export type TPpvEventPlaybackSpec = {
  accessControl: "TRANSACTION" | AccessControl.TRANSACTION;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "LIVE" | WatchMode.LIVE;
} & TVideoId;

export type TNpvrLivePlaybackSpec = {
  accessControl: "NPVR" | AccessControl.NPVR;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "LIVE" | WatchMode.LIVE;
} & TVideoId;

export type TNpvrStartoverPlaybackSpec = {
  accessControl: "NPVR" | AccessControl.NPVR;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "STARTOVER" | WatchMode.STARTOVER;
} & TVideoId;

export type TNpvrVodPlaybackSpec = {
  accessControl: "NPVR" | AccessControl.NPVR;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "ONDEMAND" | WatchMode.ONDEMAND;
} & TVideoId;

export type TTvodPlaybackSpec = {
  accessControl: "TRANSACTION" | AccessControl.TRANSACTION;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "ONDEMAND" | WatchMode.ONDEMAND;
} & TVideoId;

export type TSvodPlaybackSpec = {
  accessControl: "SUBSCRIPTION" | AccessControl.SUBSCRIPTION;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "ONDEMAND" | WatchMode.ONDEMAND;
} & TVideoId;

export type TTrailerPlaybackSpec = {
  accessControl: "FREE" | AccessControl.FREE;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "TRAILER" | WatchMode.TRAILER;
} & TVideoId;

export type TSubscriptionStartoverPlaybackSpec = {
  accessControl: "SUBSCRIPTION" | AccessControl.SUBSCRIPTION;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "STARTOVER" | WatchMode.STARTOVER;
} & TVideoId;

export type TTransactionStartoverPlaybackSpec = {
  accessControl: "TRANSACTION" | AccessControl.TRANSACTION;
  videoIdType: "MEDIA" | VideoIdType.MEDIA;
  watchMode: "STARTOVER" | WatchMode.STARTOVER;
} & TVideoId;

export type TChannelPlaybackSpec = {
  accessControl: "SUBSCRIPTION" | AccessControl.SUBSCRIPTION;
  videoIdType: "CHANNEL" | VideoIdType.CHANNEL;
  watchMode: "LIVE" | WatchMode.LIVE;
} & TVideoId;

export type TNpvrInfo = {
  media: {
    isRecordable: boolean;
    isSeriesRecordable: boolean;
    metadata: {
      id: string;
      seriesId?: string;
      title: string;
    };
    recordableBefore: null | (number | undefined);
    recordedPlaybackSpec?: TPlaybackSpec;
    seriesRecording: boolean;
  };
};

export type TPlaybackConfig = {
  autoplay?: boolean;
  overrideStreamUrl?: string;
  overrideVideoElement?: HTMLVideoElement;
  playbackContext?: TPlaybackSpec;
  playbackSpec: TPlaybackSpec;
  selectedAssetAudioLanguage?: "undefined" | keyof TLanguageCode;
  startTimeInSeconds?: number;
};

export type TPlaybackSpec =
  | TChannelPlaybackSpec
  | TNpvrLivePlaybackSpec
  | TNpvrStartoverPlaybackSpec
  | TNpvrVodPlaybackSpec
  | TPpvEventPlaybackSpec
  | TSubscriptionEventPlaybackSpec
  | TSubscriptionStartoverPlaybackSpec
  | TSvodPlaybackSpec
  | TTrailerPlaybackSpec
  | TTransactionStartoverPlaybackSpec
  | TTvodPlaybackSpec;

export type TNextEpisode = {
  episode: number;
  episodeDescription: string;
  playbackSpec: TPlaybackSpec;
  season: number;
  seriesTitle: string;
  validFrom: number;
  validTo: number;
};

export type TRelatedContentType = "CHANNEL" | "SERIES" | "SPORT_EVENT";
export enum RecordingIdType {
  Media = "MEDIA",
  Series = "SERIES",
}

export type TContentMetadata = {
  canStartOver?: boolean;
  channelPlaybackSpec?: TPlaybackSpec;
  endTime?: number;
  episode?: number;
  images?: {
    backdrop16x9?: string;
    screenshot16x9?: string;
    showcard16x9?: string;
    showcard2x3?: string;
  };
  linearPlaybackSpec?: TPlaybackSpec;
  logo?: string; // url för bild, just nu bara för kanaler
  nextEpisode?: TNextEpisode;
  npvrInfo?: TNpvrInfo;
  poster?: string; // url to poster, for movies/series
  programsInSeekRange?: Program[];
  relatedContent?: {
    items: TUnfortunatelyAny;
    type: TRelatedContentType;
  };
  season?: number;
  seriesTitle?: string;
  startoverPlaybackSpec?: TPlaybackSpec;
  startTime?: number;
  title: string;
};

export type TEngineVariantRestrictions = {
  maxBandwidth?: number;
  maxFrameRate?: number;
  maxHeight?: number;
  maxPixels?: number;
  maxWidth?: number;
  minBandwidth?: number;
  minFrameRate?: number;
  minHeight?: number;
  minPixels?: number;
  minWidth?: number;
};

export type TTooltipTranslations = {
  CLOSE_PLAYER: string;
  ENTER_FULLSCREEN: string;
  ENTER_PIP: string;
  EXIT_FULLSCREEN: string;
  PAUSE: string;
  PLAY: string;
  RECORD: string;
  RELATED: string;
  SKIP_BACKWARD: string;
  SKIP_FORWARD: string;
  START_CASTING: string;
  START_OVER: string;
  STOP_CASTING: string;
};

export type TTranslations = {
  audioTracks: string;
  ayswButtonText: string;
  ayswHeading: string;
  ayswWarning: string;
  closeModalButtonText: string;
  closeRelatedContent: string;
  descriptiveAudio: string;
  episode: string;
  eventEnded: string;
  eventNotStarted: string;
  hardOfHearingSubtitles: string;
  language: string;
  languages: TLanguageCode;
  live: string;
  next: string;
  notPlayable: string;
  off: string;
  playingNow: string;
  playNext: string;
  recordEpisode: string;
  recordingEdit: string;
  recordingFailedModalBody: string;
  recordingFailedModalTitle: string;
  recordingOngoing: string;
  recordingStart: string;
  recordingStartToast: string;
  recordingStop: string;
  recordingStopToast: string;
  recordSeries: string;
  relatedContentSeries: string;
  scrubbingForwardNotAllowed: string;
  scrubbingNotAllowed: string;
  season: string;
  skipOpeningCredits: string;
  skipRecap: string;
  startOver: string;
  subtitleTracks: string;
  tooltips?: TTooltipTranslations;
  watchCredits: string;
  zapModalBody: string;
  zapModalCancel: string;
  zapModalConfirm: string;
  zapModalNpvrBody: string;
  zapModalTitle: string;
};

export type ShakaVariantTrack = {
  active: boolean;
  audioBandwidth?: number;
  audioCodec?: string;
  audioId?: number;
  audioRoles: Array<string>;
  audioSamplingRate?: number;
  bandwidth: number;
  channelsCount?: number;
  codecs?: string;
  frameRate?: number;
  height?: number;
  id: number;
  kind?: string;
  label?: string;
  language: string;
  mimeType?: string;
  originalAudioId?: string;
  originalTextId?: string;
  originalVideoId?: string;
  pixelAspectRatio?: string;
  primary: boolean;
  roles: Array<string>;
  type: string;
  videoBandwidth?: number;
  videoCodec?: string;
  videoId?: number;
  width?: number;
};

export type TAudioTrack = {
  isoCode: keyof TLanguageCode;
  type: TAudioTrackType;
};

export type TTextTrack = {
  isoCode: keyof TLanguageCode;
  type: TTextTrackType;
};

// eslint-disable-next-line
export type TUnfortunatelyAny = any;

export type TServiceLayerConfig = {
  application: ApplicationInfo;
  auth: IAuthenticationComponent;
  browser?: BrowserInfo;
  enableNielsenTracker?: boolean;
  enableCoreTracker?: boolean;
  fetchOverride?: TFetchMethod;
  operatingSystemFirmwareVersion?: string;
  operatingSystemName?: string;
  operatingSystemVersion?: string;
  playbackTrackingUrl: string;
  serviceId: "cmore" | "telia-play";
  user?: UserInfo;
  youboraAccount: "tv4" | "tv4dev";
} & Omit<ServiceConfig, "loginGatewayUrl" | "tvClientGatewayUrl">;

export type TPlayerConfig = Omit<
  TServiceLayerConfig,
  | "graphClientName"
  | "graphClientVersion"
  | "operatingSystemFirmwareVersion"
  | "operatingSystemName"
  | "operatingSystemVersion"
>;

/**
 * @remarks The callbacks that an engine implementation is expected to fire for the
 * Playback Service to be able to perform its duties.
 *
 * See link to the event record to see corresponding events that will appear on the event channel.
 *
 * {@link WebPlayerEventRecord}
 * {@link TEventMethods}
 */
export type TEngineCallbacks = {
  // @remarks Trigger when a fatal error occurs
  // no other callbacks should be triggered for the session
  // @remarks Trigger when audio track has changed
  onAudioTrackChanged: (d: AudioTrackChangedEvent["payload"]) => void;
  // regular web browsers. Unlikely to be relevant on Smart TV or STBs.
  onAutoPlayBlocked: (d: TAutoplayBlockerEvent["payload"]) => void;
  // @remarks OPTIONAL: Trigger when the video element has been prepared and is
  // @remarks Trigger when bitrate has changed
  onBitrateChanged: (d: BitrateChangedEvent["payload"]) => void;
  // @remarks Trigger when buffering has finished
  onBuffered: (d: BufferedEvent["payload"]) => void;
  // @remarks Trigger on time update, position should be in seconds and unmodified
  // e.g. do not add or remove advert time. The numbers will be modified by the playback service
  // Should not trigger before onLoaded.
  onBuffering: (d: BufferingEvent["payload"]) => void;
  // @remarks Trigger when end of stream is reached. No other callbacks should be triggered
  // @remarks Trigger when CDN for fetching segments changes
  onCdnChange: (d: CdnChangedEvent["payload"]) => void;
  // @remarks Trigger when the video is loaded with enough buffer
  // to be ready to start playing. At this point it is safe to start interacting
  // with the controls object.
  //
  // No other callback should be called before onLoading. It is the event that signifies to the Playback Service,
  // and to put custom events in the Youbora playback session timeline
  onCustomTrackingEvent: (d: CustomTrackingEvent["payload"]) => void;
  // @remarks Trigger when video playback is paused after a call to controls.pause(). Should not trigger when the player
  // @remarks OPTIONAL: Trigger when dropped frames have been detected
  onDroppedFrames: (d: DroppedFramesEvent["payload"]) => void;
  // @remarks Trigger when a play request has been made and acknowledged by the
  // after this has been reached, the playback session will tear itself down.
  onEnded: (d: TEndOfStreamEvent["payload"]) => void;
  // @remarks Trigger when video starts playing, either initially or after having
  // been paused. Seeks or buffers do not count as video having been paused.
  //
  // An example callback sequence would be onPaused -> onPlay (user requested to play) -> onPlaying (video is playing again).
  //
  // A play requested during a seek or a buffer should have the following sequence:
  //
  // seeking -> pause -> play -> seeked -> playing
  //
  // once this has been called.
  onError: (e: PlaybackErrorEvent["payload"]) => void;
  // the tracks that the video will begin playing with, but before it has started playing
  onInitialActiveTracks: (d: TInitialActiveTracksEvent["payload"]) => void;
  // @remarks Trigger every time a seek to a new position is requested, regardless
  // of if there is an already ongoing seek or not, since each new seek indicates a new target.
  //
  // No calls to onBuffering should be made during a seek.
  //
  // The following is allowed:
  //
  /** @remarks Trigger when it can be detected from the manifest if the stream is live or not.
   Needed since the app cannot know if the manifest SGW returns will be exactly
   what the player asked for, depending on timing a "Catchup" VOD can still be an ongoing live
   recording.

   A DASH manifest is live if it is tagged as "dynamic". It is not live if it is tagged as "static".

   An HLS manifest is considered live if there is no end tag.

   This callback only needs to trigger once, when the manifest is initially parsed. It does not need
   to trigger if the manifest switches from dynamic to static (e.g. an ongoing recording finishes).
  */
  onIsLive: (d: TIsLiveEvent["payload"]) => void;
  // @remarks Trigger when available language tracks (audio, subtitles) have changed
  onLanguagesUpdated: (d: TLanguagesEvent["payload"]) => void;
  // and third party services, that initial loading of the video is done.
  onLoaded: (d: LoadedEvent["payload"]) => void;
  // @remarks OPTIONAL: Trigger when a manifest goes from dynamic to static (DASH), or an end tag appears (HLS)
  onManifestTypeChanged: (d: ManifestTypeChangedEvent["payload"]) => void;
  // enters buffering or seeking. The user is allowed to pause during a buffer or a seek.
  onPaused: (d: PausedEvent["payload"]) => void;
  // underlying engine, e.g. MSE "play" event.
  onPlay: (d: PlayEvent["payload"]) => void;
  // pause -> buffering -> play -> buffered -> playing
  onPlaying: (d: PlayingEvent["payload"]) => void;
  // @remarks Trigger when seek has finished
  onSeeked: (d: SeekedEvent["payload"]) => void;
  // @remarks OPTIONAL: Trigger when the initial subtitle and audio language track is known:
  // seeking -> seeking -> seeking -> seeked
  onSeeking: (d: SeekingEvent["payload"]) => void;
  // @remarks Trigger on custom tracking events. Used primarily for CoreTracking
  // like STARTOVER and MEDIA LIVE events.
  onSeekRangeUpdate: (d: TSeekRangeUpdateEvent["payload"]) => void;
  // @remarks Trigger when a stream metadata cue has been detected,
  // Playback speed has either increased or decreased.
  onSpeedChange: (d: TSpeedChangeEvent["payload"]) => void;
  // to enable linear ad integrations to function. Not relevant for ads in VODs.
  onStreamCue: (d: StreamCueEvent["payload"]) => void;
  // @remarks Trigger when buffering has started, trigger only once per buffering instance.
  //
  // Should not trigger during a seek.
  //
  // @remarks Trigger when text track has changed
  onTextTrackChanged: (d: TextTrackChangedEvent["payload"]) => void;
  // @remarks Trigger when text track visibility has changed (subtitles turned on/off)
  onTextTrackVisibility: (d: TextTrackVisibilityEvent["payload"]) => void;
  // @remarks Trigger whenever the seekable range is updated. This event signals to the application
  // how big the seekable window is. Relevant for moving windows like timeshift/DVR, and growing windows
  // before being pushed to the event channel for the app to consume.
  onTimeUpdate: (d: TimeUpdateEvent["payload"]) => void;
  // ready to be used by the rest of the application
  onVideoElementReady: (d: TVideoElementReadyEvent["payload"]) => void;
  // @remarks SEMI-OPTIONAL: Trigger when automatic playback is denied by the browser. Primarily relevant for
  // @remarks Trigger on volume change or mute toggle
  onVolumeChanged: (d: VolumeChangedEvent["payload"]) => void;
};

export type TVideoEventHandlerOptions = {
  callbacks: TEngineCallbacks;
  videoElement: HTMLVideoElement;
};

export type TThumbnail = {
  height: number; // The thumbnail height in px.
  positionX: number; // The thumbnail left position in px.
  positionY: number; // The thumbnail top position in px.
  uris: Array<string>; // An array of URIs to attempt. They will be tried in the order they are given.
  width: number; // The thumbnail width in px.
};

export type TImageProxyProps = {
  fit?: "crop" | "resize";
  height: number;
  scale?: number;
  uri: string;
  width: number;
};

export type TDomElements = {
  textElement: HTMLDivElement;
  videoElement: HTMLVideoElement;
};
