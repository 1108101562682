import styled from "@emotion/styled";
import { FC, MouseEvent } from "react";

import { TPlaybackButtonProps, TPlaybackIconProps } from "../@types/types";
import { HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { GridIcon } from "./elements";

const PlaybackIcon = styled(GridIcon)<TPlaybackIconProps>`
  grid-column: ${({ position }) => position};
  grid-row: ${({ smallLayout }) => (smallLayout ? "4 / 6" : "9")};
  margin-bottom: ${({ smallLayout }) => (smallLayout ? "-25%" : "0")};
`;
export const PlaybackButton: FC<TPlaybackButtonProps> = ({
  clickHandler,
  paused,
  PauseIcon,
  PlayIcon,
  position,
  smallLayout,
  tooltips,
}) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.detail !== 0) {
      clickHandler();
    }
  };
  return (
    <PlaybackIcon
      aria-label={`${
        paused ? tooltips?.PLAY : tooltips?.PAUSE
      } (${HotkeyTooltips.TOGGLE_PLAY.toUpperCase()})`}
      data-microtip-position="top"
      data-tooltip
      onClick={handleClick}
      onDoubleClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          clickHandler();
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onMouseLeave={(e) => e.currentTarget.blur()}
      position={position}
      smallLayout={smallLayout}
      tabIndex={0}
    >
      {paused ? <PlayIcon /> : <PauseIcon />}
    </PlaybackIcon>
  );
};
