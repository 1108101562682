import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import { FC } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { separator } from "../../constants";
import { Hotkeys } from "../../hooks/global-hotkeys.hook";
import { brandRed } from "../styles";

const LiveMarkerPosition = styled.div`
  position: relative;
`;

const LiveMarkerOuter = styled.div`
  position: absolute;
  bottom: 0.8em;
`;

const LiveMarkerInner = styled.div<{
  atLiveEdge: boolean;
  canJumpToLive: boolean;
}>`
  cursor: ${({ canJumpToLive }) => (canJumpToLive ? `pointer;` : `default;`)}
  margin-left: -50%;
  width: 100%;
  font-size: 0.8em;
  line-height: 1.6em;
  height: 1.6em;
  padding: 0 0.5em;
  background: ${({ atLiveEdge }) =>
    atLiveEdge ? brandRed : `rgba(140, 140, 140, 0.3)`};
  border-radius: 0.2em;
  white-space: nowrap;
`;

export type TLiveMarkerProps = {
  atLiveEdge: boolean;
  canJumpToLive: boolean;
  onJumpToLive: TCallback;
  seekWindowPercent: number;
};

export const LiveMarker: FC<TLiveMarkerProps> = ({
  atLiveEdge,
  canJumpToLive,
  onJumpToLive,
  seekWindowPercent,
}) => {
  const onInteraction = () => {
    if (canJumpToLive) onJumpToLive();
  };

  useHotkeys(Hotkeys.TOGGLE_STARTOVER, onInteraction, {}, [onInteraction]);

  return (
    <LiveMarkerPosition style={{ left: `${seekWindowPercent * 100}%` }}>
      <LiveMarkerOuter>
        <LiveMarkerInner
          atLiveEdge={atLiveEdge}
          canJumpToLive={canJumpToLive}
          onClick={onInteraction}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              onInteraction();
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          role="button"
          tabIndex={0}
        >
          {`${separator} LIVE`}
        </LiveMarkerInner>
      </LiveMarkerOuter>
    </LiveMarkerPosition>
  );
};
