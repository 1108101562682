import styled from "@emotion/styled";
import { TCallback, TNpvrInfo } from "@telia-company/tv.web-player-shared";
import { FC } from "react";

import { TPlayerIcon } from "../@types";
import { TRecordButtonProps } from "../@types/types";
import { noop } from "../utils";
import {
  useCustomStyles,
  usePlayerState,
  useTranslations,
} from "./context-provider";
import { GridIcon } from "./elements";
// import { HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { TextOverflowEllipsis } from "./overlays/elements";
import { useRecordings } from "./recordings.hook";
import { Spinner } from "./spinner";
import { animatedHidden, animatedVisible } from "./styles";

const RecordingIcon = styled(GridIcon)<{ borderRadius: string }>`
  grid-column: 1;
  height: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-bottom-left-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
  border-top-left-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
`;

const SpinnerContainer = styled.div`
  position: relative;
  grid-column: 1;
  height: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
`;

const RecordingText = styled.span<{ isAlone: boolean }>`
  grid-column: 2 / 4;
  overflow: hidden;
  height: 100%;
  font-size: 0.9em;
  ${({ isAlone }) => (isAlone ? `width: min-content;` : ``)}
`;

const TextBgWrapper = styled.div<{ borderRadius: string }>`
  height: 100%;
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
  border-top-right-radius: ${({ borderRadius }) => borderRadius || "0.5em"};

  display: flex;
  align-items: center;
  max-width: max-content;
`;

const TextInnerWrapper = styled(TextOverflowEllipsis)`
  padding: 0 1em 0 0;
`;

const RecordingPopup = styled.div`
  cursor: default;
  flex-shrink: 0;

  position: absolute;
  bottom: 11%;

  transform: translateX(25%);

  padding: 0 0 1em;
`;

const RecordButtonWrapper = styled.div<{
  position: string;
  unavailable: boolean;
}>`
  cursor: ${({ unavailable }) => (unavailable ? `not-allowed;` : `pointer;`)}
  ${({ unavailable }) => (unavailable ? `filter: opacity(0.5);` : ``)}

  ${({ position }) => `
    grid-column: ${position};
    grid-row: 9;
  `}

  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;

  align-items: center;
  justify-content: center;

  --microtip-transition-delay: 0s;
  ::after {
    background: rgba(0, 0, 0, 0.8) !important;
    border-radius: 0.25em !important;
    padding: 0 0.75em !important;
    font-size: 0.9em !important;
    line-height: 1.8em !important;
    margin-bottom: 1.8em !important;
  }
  ::before {
    background: unset !important;
  }
  &:hover {
    --microtip-transition-delay: 1s;

    ${RecordingIcon}, ${TextBgWrapper} {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  ${RecordingPopup} {
    display: grid;
    ${animatedHidden}
  }

  &:hover {
    ${RecordingPopup} {
      ${animatedVisible}
    }

    svg {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }

  &:hover {
    svg {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

const IconWrapper = styled.div`
  height: 1.2em;
  width: 1.2em;
`;

const RecordingOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 8px;
  width: fit-content;
`;

const RecordingOption = styled.div<{ unavailable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  gap: 0.4em;
  padding: 0.6em;
  margin-top: 0.4em;
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 24px;
  }
  ${({ unavailable }) => (unavailable ? `cursor: not-allowed;` : ``)}
  ${({ unavailable }) => (unavailable ? `filter: opacity(0.5);` : ``)}
`;

type TRecordingMenuProps = {
  canRecordSeries: boolean;
  IconRecording: TPlayerIcon;
  IconRecordingSeries: TPlayerIcon;
  isRecording: boolean;
  isRecordingSeries: boolean;
  noLongerRecordable: boolean;
  recordingText: string;
};

const RecordingMenu: FC<TRecordingMenuProps> = ({
  canRecordSeries,
  IconRecording,
  IconRecordingSeries,
  isRecording,
  isRecordingSeries,
  noLongerRecordable,
}) => {
  const { toggleSeriesRecording, toggleSingleRecording } = useRecordings();
  const { recordEpisode, recordingStop, recordSeries } = useTranslations();
  if (!canRecordSeries) return null;

  return (
    <RecordingPopup>
      <RecordingOptionWrapper>
        <RecordingOption
          onClick={noLongerRecordable ? noop : toggleSingleRecording}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              if (!noLongerRecordable) {
                toggleSingleRecording();
              }
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          role="button"
          tabIndex={0}
          unavailable={noLongerRecordable}
        >
          <IconWrapper>
            <IconRecording />
          </IconWrapper>
          <span>{isRecording ? recordingStop : recordEpisode}</span>
        </RecordingOption>
        <RecordingOption
          onClick={toggleSeriesRecording}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              toggleSeriesRecording();
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          role="button"
          tabIndex={0}
          unavailable={false}
        >
          <IconWrapper>
            <IconRecordingSeries />
          </IconWrapper>
          <span>{isRecordingSeries ? recordingStop : recordSeries}</span>
        </RecordingOption>
      </RecordingOptionWrapper>
    </RecordingPopup>
  );
};

type TGetAvailableAction = {
  action: (() => void) | TCallback;
  hover: boolean;
  icon: TPlayerIcon;
  text: string;
} | null;

type TGetAvailableActionProps = {
  AlternateIcon: TPlayerIcon;
  Icon: TPlayerIcon;
  npvrInfo?: TNpvrInfo;
  texts: {
    recordingEdit: string;
    recordingStart: string;
    recordingStop: string;
    // recordingSeriesStart: string
    // recordingSeriesStop: string
  };
  toggleSeriesRecording: () => void;
  toggleSingleRecording: () => void;
};

const getAvailableAction = ({
  AlternateIcon,
  Icon,
  npvrInfo,
  texts: { recordingEdit, recordingStart, recordingStop },
  toggleSeriesRecording,
  toggleSingleRecording,
}: TGetAvailableActionProps): TGetAvailableAction => {
  if (!npvrInfo) return null;
  const {
    media: {
      isRecordable,
      isSeriesRecordable,
      recordedPlaybackSpec,
      seriesRecording,
    },
  } = npvrInfo;
  // we have a single recording, and the option to do a series recording
  // no action, since the button will have to be hovered to get to the options
  if (recordedPlaybackSpec && isSeriesRecordable && !seriesRecording) {
    return {
      action: noop,
      hover: true,
      icon: AlternateIcon,
      text: recordingEdit,
    };
  }

  // we can record series, but not media (strange edge case?)
  if (!isRecordable && isSeriesRecordable && !seriesRecording) {
    return {
      action: toggleSeriesRecording,
      hover: false,
      icon: AlternateIcon,
      text: recordingStart,
    };
  }
  // we have an ongoing series recording (and may or may not have a single recording)
  if (seriesRecording) {
    return {
      action: toggleSeriesRecording,
      hover: false,
      icon: AlternateIcon,
      text: recordingStop,
    };
  }

  // we can record media, but not series
  if (isRecordable && !isSeriesRecordable && !seriesRecording) {
    return {
      action: toggleSingleRecording,
      hover: false,
      icon: Icon,
      text: recordingStart,
    };
  }
  // we have an ongoing single recording, we can't do series recordings
  if (recordedPlaybackSpec && !isSeriesRecordable && !seriesRecording) {
    return {
      action: toggleSingleRecording,
      hover: false,
      icon: AlternateIcon,
      text: recordingStop,
    };
  }
  // default case, we have the option to do both a single recording and a series recording
  return {
    action: noop,
    hover: true,
    icon: Icon,
    text: `${recordingStart}...`,
  };
};

export const RecordButton: FC<TRecordButtonProps> = ({
  AlternateIcon,
  Icon,
  IconRecordingSeries,
  isAlone,
  isLoading,
  isRecording,
  isRecordingSeries,
  onMouseEnter,
  onMouseLeave,
  position,
}) => {
  const { borderRadius } = useCustomStyles();
  const { toggleSeriesRecording, toggleSingleRecording } = useRecordings();
  const {
    metadata: { npvrInfo },
  } = usePlayerState();
  const { recordingEdit, recordingStart, recordingStop } = useTranslations();
  // if we have only one option, clicking the button will trigger it,
  // no hover menu will be shown
  const availableActions = getAvailableAction({
    AlternateIcon,
    Icon,
    npvrInfo,
    texts: {
      recordingEdit,
      recordingStart,
      recordingStop,
    },
    toggleSeriesRecording,
    toggleSingleRecording,
  });

  const getRecordingIcon = () => {
    if (!npvrInfo) return null;
    const {
      media: { isRecordable, isSeriesRecordable },
    } = npvrInfo;

    if (!isRecordable && isSeriesRecordable && !isRecording)
      return <IconRecordingSeries />;
    if (isRecording) return <AlternateIcon />;
    if (!isRecording) return <Icon />;
    return null;
  };

  if (!availableActions) return null;
  const noLongerRecordable = !!(npvrInfo?.media.recordableBefore
    ? Date.now() > npvrInfo?.media.recordableBefore
    : false);
  const { action, hover, icon, text } = availableActions;

  return (
    <RecordButtonWrapper
      aria-label={`${text}`}
      // data-tooltip
      onClick={hover || noLongerRecordable ? noop : action}
      onDoubleClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (!hover && !noLongerRecordable) {
          action();
        }
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseEnter={hover ? onMouseEnter : undefined}
      // onMouseLeave={(e) => e.currentTarget.blur()}
      onMouseLeave={hover ? onMouseLeave : undefined}
      // data-microtip-position="top"
      position={position}
      role="button"
      tabIndex={0}
      unavailable={!hover && noLongerRecordable}
    >
      <RecordingIcon borderRadius={borderRadius}>
        {hover ? (
          <RecordingMenu
            canRecordSeries
            IconRecording={icon}
            IconRecordingSeries={IconRecordingSeries}
            isRecording={isRecording}
            isRecordingSeries={isRecordingSeries}
            noLongerRecordable={noLongerRecordable}
            recordingText={text}
          />
        ) : null}
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : null}
        {!isLoading ? getRecordingIcon() : null}
      </RecordingIcon>
      <RecordingText isAlone={isAlone}>
        <TextBgWrapper borderRadius={borderRadius}>
          <TextInnerWrapper>{text}</TextInnerWrapper>
        </TextBgWrapper>
      </RecordingText>
    </RecordButtonWrapper>
  );
};
