import styled from "@emotion/styled";
import { FC } from "react";

import { TSkipForwardButtonProps } from "../@types/types";
import { HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { GridIcon } from "./elements";

const SkipForwardIcon = styled(GridIcon)<{
  position: string;
  smallLayout: boolean;
}>`
  grid-column: ${({ position }) => position};
  grid-row: ${({ smallLayout }) => (smallLayout ? "4 / 7" : "9")};
`;

export const SkipForwardButton: FC<TSkipForwardButtonProps> = ({
  Icon,
  onClick,
  position,
  smallLayout,
  tooltip,
}) => (
  <SkipForwardIcon
    aria-label={`${tooltip} (${HotkeyTooltips.SKIP_FORWARD.toUpperCase()})`}
    data-microtip-position="top"
    data-tooltip
    onDoubleClick={(e) => e.stopPropagation()}
    onKeyDown={(e) => {
      if (e.key === " " || e.key === "Enter") {
        onClick(1);
        e.stopPropagation();
        e.preventDefault();
      }
    }}
    onMouseLeave={(e) => e.currentTarget.blur()}
    onPointerDown={() => onClick(1)}
    position={position}
    smallLayout={smallLayout}
    tabIndex={0}
  >
    <Icon />
  </SkipForwardIcon>
);
