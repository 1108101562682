import {
  PlayerControls,
  TThumbnail,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, RefObject, SetStateAction, useEffect } from "react";

import { useFeatureToggle, useUiScale } from "../components/context-provider";
import { noop } from "../utils";

export type TCalculateThumbnailOffset = {
  scaleFactor: number;
  thumbnailWidth: number | undefined;
  videoElementLeft: number | undefined;
  videoElementRight: number | undefined;
  wrapperLeft: number | undefined;
  wrapperRight: number | undefined;
};

export type TUseThumbnailScrubbingProps = {
  controls: PlayerControls | undefined;
  hoverPercent: null | number;
  playbackType: null | string;
  seekWindowPercent: null | number;
  setThumbnail: Dispatch<
    SetStateAction<{
      img: TThumbnail;
      offset: {
        left: null | number;
        right: null | number;
      } | null;
      pos: number;
    } | null>
  >;
  thumbnailWrapperRef: RefObject<HTMLDivElement>;
  videoElement: HTMLVideoElement | undefined;
};

const calculateThumbnailOffset = ({
  scaleFactor,
  thumbnailWidth,
  videoElementLeft,
  videoElementRight,
  wrapperLeft,
  wrapperRight,
}: TCalculateThumbnailOffset): {
  left: null | number;
  right: null | number;
} | null => {
  if (
    typeof videoElementLeft !== "number" ||
    typeof wrapperLeft !== "number" ||
    typeof videoElementRight !== "number" ||
    typeof wrapperRight !== "number" ||
    typeof thumbnailWidth !== "number"
  )
    return null;

  // if offset is less than half the width, we'll use it to move the thumbnail
  // so it doesn't extend outside the video element
  const offsetLeft =
    Math.abs(videoElementLeft - wrapperLeft) < thumbnailWidth / 2
      ? thumbnailWidth / 2 - Math.abs(videoElementLeft - wrapperLeft)
      : null;

  const offsetRight =
    wrapperRight - videoElementRight > thumbnailWidth / 2
      ? wrapperRight - videoElementRight - thumbnailWidth / 2
      : null;

  return {
    left: offsetLeft ? offsetLeft / scaleFactor : null,
    right: offsetRight ? offsetRight / scaleFactor : null,
  };
};

export const useThumbnailScrubbing = ({
  controls,
  hoverPercent,
  playbackType,
  seekWindowPercent,
  setThumbnail,
  thumbnailWrapperRef,
  videoElement,
}: TUseThumbnailScrubbingProps) => {
  const { thumbnailScrubbing } = useFeatureToggle();
  const scaleFactor = useUiScale();
  useEffect(() => {
    if (
      !thumbnailScrubbing ||
      !seekWindowPercent ||
      !controls ||
      typeof hoverPercent !== "number" ||
      playbackType === "linear-channel"
    )
      return noop;
    let aborted = false;

    const videoElementRect = videoElement?.getBoundingClientRect();
    const wrapperRect = thumbnailWrapperRef.current
      ? thumbnailWrapperRef.current.getBoundingClientRect()
      : null;

    controls
      ?.getThumbnail({ percent: hoverPercent })
      .then((thumb: null | TThumbnail) => {
        if (aborted) return;
        if (!thumb) return;
        const offset = calculateThumbnailOffset({
          scaleFactor,
          // so we'll use an initial guesstimation based on 320 px wide thumbnail
          thumbnailWidth: wrapperRect?.width || 320 * scaleFactor,
          videoElementLeft: videoElementRect?.left,
          videoElementRight: videoElementRect?.right,
          // we can't get the thumbnail width until we've rendered a thumbnail,
          wrapperLeft: wrapperRect?.left,
          wrapperRight: wrapperRect?.right,
        });
        setThumbnail({
          img: thumb,
          offset,
          pos: hoverPercent * seekWindowPercent,
        });
      })
      .catch(() => {
        setThumbnail(null);
      });

    return () => {
      aborted = true;
    };
  }, [
    hoverPercent,
    controls,
    setThumbnail,
    seekWindowPercent,
    videoElement,
    scaleFactor,
    thumbnailScrubbing,
    thumbnailWrapperRef,
    playbackType,
  ]);
};
