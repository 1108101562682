import styled from "@emotion/styled";
import { TThumbnail } from "@telia-company/tv.web-player-shared";
import {
  Dispatch,
  FC,
  PointerEvent,
  RefObject,
  SetStateAction,
  useState,
} from "react";

import { UiEventTypes } from "../../constants";
import { useThumbnailScrubbing } from "../../hooks/thumbnail-scrubbing.hook";
import { TSeekRange } from "../../usePlayer";
import {
  dotAnimation,
  getHHMMFromEpoch,
  getZeroBasedTimeString,
  throttle,
} from "../../utils";
import {
  useClickHandler,
  useDispatch,
  usePlayerState,
  useTranslations,
} from "../context-provider";

const SeekableAreaDiv = styled.div<{ seekAllowed: boolean }>`
  height: 100%;
  position: absolute;
  cursor: ${({ seekAllowed }) => (seekAllowed ? "pointer" : "not-allowed")};
  display: flex;
  align-items: center;
`;

const getSeekableAreaPositionPercent = (evt: PointerEvent<HTMLDivElement>) => {
  const rect = evt.currentTarget.getBoundingClientRect();
  const percent = (evt.clientX - rect.left) / rect.width;

  return Math.min(Math.max(percent, 0), 1);
};

export type TSeekableAreaProps = {
  isDragging: boolean;
  keyboardScrubbingPosition: null | number;
  liveSeekEnabled: boolean;
  playbackType: null | string;
  progressDotRef: RefObject<HTMLDivElement>;
  progressPercent: number;
  seekWindowPercent: null | number;
  setDragPosition: Dispatch<SetStateAction<null | number>>;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  setMouseProgressPosition: Dispatch<SetStateAction<number>>;
  setSeekLabelTimestamp: Dispatch<SetStateAction<null | string>>;
  setSeekRequested: Dispatch<SetStateAction<boolean>>;
  setThumbnail: Dispatch<
    SetStateAction<{
      img: TThumbnail;
      offset: { left: null | number; right: null | number } | null;
      pos: number;
    } | null>
  >;
  thumbnailWrapperRef: RefObject<HTMLDivElement>;
};

type TCalculateTimestamp = {
  endTime?: number;
  liveSeekEnabled: boolean;
  percent: number;
  playbackType: null | string;
  seekRange: TSeekRange | undefined;
  startTime?: number;
};

export const calculateTimestamp = ({
  endTime,
  liveSeekEnabled,
  percent,
  playbackType,
  seekRange,
  startTime,
}: TCalculateTimestamp) => {
  const targetSeekPosition = seekRange
    ? seekRange.start + (seekRange.end - seekRange.start) * percent
    : null;

  if (seekRange && targetSeekPosition !== null) {
    const dvrWindowSizeInMs = (seekRange.end - seekRange.start) * 1000;
    const isOngoing =
      startTime && endTime && Date.now() > startTime && Date.now() < endTime;

    let label = getZeroBasedTimeString(targetSeekPosition - seekRange.start);
    // calculate differently depending on if programme is ongoing or not
    if (isOngoing && liveSeekEnabled && playbackType === "linear-channel") {
      label = getHHMMFromEpoch(
        Date.now() - dvrWindowSizeInMs + dvrWindowSizeInMs * percent
      );
    } else if (
      !isOngoing &&
      liveSeekEnabled &&
      playbackType === "linear-channel" &&
      startTime
    ) {
      // not ongoing, calculate what timestamp to show based on % of progressbar, where
      // progressbar is program length
      label = getHHMMFromEpoch(startTime + dvrWindowSizeInMs * percent);
    }

    return label;
  }
  return null;
};

export const SeekableArea: FC<TSeekableAreaProps> = ({
  isDragging,
  keyboardScrubbingPosition,
  liveSeekEnabled,
  playbackType,
  progressDotRef,
  progressPercent,
  seekWindowPercent,
  setDragPosition,
  setIsDragging,
  setMouseProgressPosition,
  setSeekLabelTimestamp,
  setSeekRequested,
  setThumbnail,
  thumbnailWrapperRef,
}) => {
  const { onSeek } = useClickHandler();
  const dispatch = useDispatch();

  const {
    controls,
    metadata: { endTime, startTime },
    seekRange,
    trickPlayRestrictions: { noFastForward } = {},
    videoElement,
  } = usePlayerState();
  const [seekAllowed, setSeekAllowed] = useState(true);
  const [hoverPercent, setHoverPercent] = useState<null | number>(null);

  const { scrubbingForwardNotAllowed } = useTranslations();

  useThumbnailScrubbing({
    controls,
    hoverPercent: hoverPercent || keyboardScrubbingPosition,
    playbackType,
    seekWindowPercent,
    setThumbnail,
    thumbnailWrapperRef,
    videoElement,
  });

  const seekHandler = (seekPercent: number) => {
    if (seekPercent > progressPercent && noFastForward) return;

    setSeekRequested(true);

    const targetSeekPosition = seekRange
      ? seekRange.start + (seekRange.end - seekRange.start) * seekPercent
      : null;

    if (targetSeekPosition !== null) {
      onSeek(targetSeekPosition);
    }

    setDragPosition(seekPercent);
  };

  const onStartDrag = (ev: PointerEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    dotAnimation({ ev, ref: progressDotRef });
    ev.currentTarget.setPointerCapture(ev.pointerId);

    if (!seekAllowed) {
      dispatch({
        payload: {
          message: scrubbingForwardNotAllowed,
        },
        type: UiEventTypes.SHOW_TOAST,
      });
      return;
    }

    setIsDragging(true);

    const percent = getSeekableAreaPositionPercent(ev);

    setDragPosition(percent);
  };

  const onDragging = (percent: number) => {
    if (!isDragging) return;

    if (!seekAllowed) {
      setDragPosition(progressPercent);
    } else {
      setDragPosition(percent);
    }
  };

  const onStopDrag = (ev: PointerEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.currentTarget.setPointerCapture(ev.pointerId);

    setIsDragging(false);

    if (!seekAllowed) return;

    const percent = getSeekableAreaPositionPercent(ev);

    seekHandler(percent);
  };

  const onShowHoverTimer = (percent: number) => {
    if (!seekWindowPercent) return;
    if (seekAllowed) {
      setSeekLabelTimestamp(
        calculateTimestamp({
          endTime,
          liveSeekEnabled,
          percent,
          playbackType,
          seekRange,
          startTime,
        })
      );
      setMouseProgressPosition(percent * seekWindowPercent);
    } else {
      setSeekLabelTimestamp(
        calculateTimestamp({
          endTime,
          liveSeekEnabled,
          percent: progressPercent,
          playbackType,
          seekRange,
          startTime,
        })
      );
      setMouseProgressPosition(progressPercent * seekWindowPercent);
    }
  };

  const onPointerLeaveHandler = () => {
    setSeekLabelTimestamp(null);
    setHoverPercent(null);
    setThumbnail(null);
    setMouseProgressPosition(0);
    dotAnimation({ ref: progressDotRef });
  };

  const onPointerMove = throttle((ev: PointerEvent<HTMLDivElement>) => {
    ev.preventDefault();
    dotAnimation({ dragging: isDragging, ev, ref: progressDotRef });

    const percent = getSeekableAreaPositionPercent(ev);

    if (noFastForward && percent > progressPercent) {
      setSeekAllowed(false);
    } else {
      setSeekAllowed(true);
    }

    onDragging(percent);
    onShowHoverTimer(percent);
    setHoverPercent(percent);
  }, 100);

  return (
    <SeekableAreaDiv
      onPointerDown={onStartDrag}
      onPointerLeave={onPointerLeaveHandler}
      onPointerMove={onPointerMove}
      onPointerUp={onStopDrag}
      seekAllowed={seekAllowed}
      style={{
        width: `${(seekWindowPercent || 0) * 100}%`,
      }}
    />
  );
};
