import "@telia-company/tv.microtip/microtip.min.css";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { TModalOptions } from "../@types/modal.types";
import { UiEventTypes } from "../constants";
import { useGlobalHotkeys } from "../hooks/global-hotkeys.hook";
import {
  TRelatedContentHeader,
  useRelatedContentHeader,
} from "../hooks/related-content-header.hook";
import { useResizeDetection } from "../hooks/resize-detection.hook";
import { useVodInactivityModal } from "../hooks/vod-inactivity-modal.hook";
import { BackButton } from "./back-button";
import {
  UiScaleContext,
  useClickHandler,
  useCustomStyles,
  useDispatch,
  useIcon,
  usePlayerControls,
  usePlayerState,
  usePublishCustomTrackingEvent,
  useTranslations,
  useUiCallbacks,
} from "./context-provider";
import { UiGrid } from "./elements";
import { OverlayPlayButton } from "./overlay-play-button";
import { FullscreenModal } from "./overlays/fullscreen-overlay";
import { InactivityWarning } from "./overlays/inactivity-warning";
import { PanelOverlay } from "./overlays/list-overlay/generic-panel/panel-overlay";
import { OverlayModal, SmallOverlayModal } from "./overlays/modal";
import { NextContentContainer } from "./overlays/next-content-container";
import { ToastOverlay } from "./overlays/toast-overlay";
import { useRelatedContentImpression } from "./related-content-impression.hook";
import { SkipIndicator } from "./skip-indicator";
import { useSkipClick } from "./skip.hook";
import { Spinner } from "./spinner";
import { TimelineActionButton } from "./timeline-action-button";
import { UiGridDependentComponents } from "./ui-grid-dependent-components";
import { UiWrapper } from "./ui-wrapper";

export const Ui: FC = () => {
  const sizeRef = useRef<HTMLDivElement>(null);
  const { onPlayContent, onRecordAndWatch, onTogglePlay } = useClickHandler();
  const { backButtonCallback } = useUiCallbacks();
  const {
    borderRadius,
    gradient,
    progressBarColor,
    relatedContentListItemBackground,
  } = useCustomStyles();
  const {
    closeRelatedContent,
    eventEnded,
    eventNotStarted,
    notPlayable,
    playingNow,
    tooltips,
  } = useTranslations();
  const { numberOfSkips, skip } = useSkipClick();
  const [smallLayout, setSmallLayout] = useState<boolean | null>(null);
  const [minimized, setMinimized] = useState<boolean | null>(null);
  const [scaleFactor, setScaleFactor] = useState<number>(1);
  const [relatedContentHeader, setRelatedContentHeader] =
    useState<null | TRelatedContentHeader>(null);

  const {
    autoplayBlocked,
    buffering,
    cast,
    liveSeekEnabled,
    loading,
    modalOptions,
    paused,
    playback,
    playbackType,
    relatedContent,
    seekIndicatorVisible,
    seeking,
    showInactivityWarning,
    showNextEpisodeOverlay,
    showRelatedContentOverlay,
    uiSpinner,
    uiVisible,
  } = usePlayerState();

  const [selectedSeason, setSelectedSeason] = useState<null | number>(null);

  // set initial selected season of a series and reset when overlay is closed
  useEffect(() => {
    if (
      relatedContent &&
      relatedContent.type === "series" &&
      !showRelatedContentOverlay
    ) {
      setSelectedSeason(relatedContent.seasonOfCurrentItem);
    }
  }, [showRelatedContentOverlay, relatedContent]);

  const dispatch = useDispatch();
  const publish = usePublishCustomTrackingEvent();

  const { IconNavigateLeft, IconNavigateRight, IconPlay } = useIcon();

  const controls = usePlayerControls();

  const { dismissInactivityModal, showInactivityModal } = useVodInactivityModal(
    {
      playbackType,
      showInactivityWarning,
    }
  );

  const onCloseRelatedContent = useMemo(
    () => () => {
      dispatch({
        payload: {},
        type: UiEventTypes.HIDE_RELATED_CONTENT,
      });
    },
    [dispatch]
  );

  useGlobalHotkeys({
    skip,
  });

  useResizeDetection({
    setMinimized,
    setScaleFactor,
    setSmallLayout,
    sizeRef,
  });

  useRelatedContentHeader({
    closeRelatedContent,
    onCloseRelatedContent,
    relatedContent,
    selectedSeason,
    setRelatedContentHeader,
    setSelectedSeason,
  });

  useRelatedContentImpression({
    publish,
    showRelatedContentOverlay,
  });

  useEffect(() => {
    // set initial focus, needed when restarting the player
    const focustarget = sizeRef.current;
    if (focustarget) {
      focustarget.focus();
    }
  }, []);

  return (
    <UiWrapper
      scaleFactor={scaleFactor}
      sizeRef={sizeRef}
      smallLayout={smallLayout}
    >
      <UiScaleContext.Provider value={scaleFactor}>
        {(cast === "WAITING" ||
          loading ||
          buffering ||
          seeking ||
          !controls ||
          uiSpinner) &&
          !autoplayBlocked &&
          !seekIndicatorVisible &&
          !showInactivityModal && <Spinner />}

        {!loading && <SkipIndicator numberOfSkips={numberOfSkips} />}

        <UiGrid
          gradient={!showInactivityModal ? gradient : ""}
          onPointerUp={(e) => {
            if (e.target === e.currentTarget) {
              if (!showRelatedContentOverlay) {
                onTogglePlay();
              }
              e.stopPropagation();
            }
          }}
          smallLayout={smallLayout}
          visible={uiVisible && !showInactivityModal && !minimized}
        >
          {/* only show playback interaction if controls is defined */}
          {!loading &&
            !cast &&
            controls &&
            (autoplayBlocked ? (
              <OverlayPlayButton clickHandler={onTogglePlay} Icon={IconPlay} />
            ) : (
              <>
                {paused && !smallLayout && !showRelatedContentOverlay && (
                  <OverlayPlayButton
                    clickHandler={onTogglePlay}
                    Icon={IconPlay}
                  />
                )}

                <UiGridDependentComponents
                  liveSeekEnabled={liveSeekEnabled}
                  showNextEpisodeOverlay={showNextEpisodeOverlay}
                  showRelatedContentOverlay={showRelatedContentOverlay}
                  skip={skip}
                />
              </>
            ))}

          {backButtonCallback && (
            <BackButton
              callback={backButtonCallback}
              tooltip={tooltips?.CLOSE_PLAYER}
            />
          )}
        </UiGrid>
        {showInactivityModal || showRelatedContentOverlay ? null : (
          <TimelineActionButton />
        )}
        {!showRelatedContentOverlay && showNextEpisodeOverlay && (
          <NextContentContainer />
        )}

        {playback && showRelatedContentOverlay && relatedContent && (
          <PanelOverlay
            header={relatedContentHeader}
            panel={{
              borderRadius,
              contentType: relatedContent.type,
              currentlyPlayingVideoId: playback.playbackSpec.videoId,
              eventEnded,
              eventNotStarted,
              itemBackground: relatedContentListItemBackground,
              LeftIcon: IconNavigateLeft,
              notPlayable,
              onItemPressed: onPlayContent,
              onRecordAndWatch,
              pageSize: relatedContent.type === "epg" ? 6 : 4,
              playingNow,
              progressBarColor,
              RightIcon: IconNavigateRight,
              selectedSeason,
              showModal: (options: TModalOptions) => {
                dispatch({
                  payload: {
                    modalOptions: options,
                  },
                  type: UiEventTypes.SHOW_MODAL,
                });
              },
            }}
            refreshInterval={5000}
            relatedContent={relatedContent}
          />
        )}

        {/*
          Different modals for different use cases, show full screen modal when switching
          channels would cause user to lose channel dvr buffer.
          Data is populated in panel.tsx
        */}
        {modalOptions && liveSeekEnabled ? (
          <FullscreenModal
            dismissModal={dismissInactivityModal}
            modalOptions={modalOptions}
            type="zapConfirmation"
          />
        ) : null}
        {modalOptions &&
          !liveSeekEnabled &&
          (smallLayout ? (
            <SmallOverlayModal modalOptions={modalOptions} />
          ) : (
            <OverlayModal modalOptions={modalOptions} />
          ))}

        <ToastOverlay />

        {!loading &&
        showInactivityWarning &&
        !showInactivityModal &&
        playbackType !== "vod" ? (
          <InactivityWarning />
        ) : null}

        {!loading &&
        showInactivityModal &&
        backButtonCallback &&
        playbackType === "vod" ? (
          <FullscreenModal dismissModal={dismissInactivityModal} />
        ) : null}
      </UiScaleContext.Provider>
    </UiWrapper>
  );
};
